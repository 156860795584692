import React, { useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const ForgotModal = ({
    show,
    setShow,
    onClose
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <Modal
            centered
            show={show}
            onHide={onClose}
            size='lg'
            className='forgot-password-modal-style'
        >
            <Modal.Header style={{ padding: '1rem 2rem', border: 'unset' }}>
                <Modal.Title className='fs-16 w-100'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            {t('auth.isForgotPassword')}
                        </div>
                        <div>
                            <button className='btn-icon btn-icon-only mb-1 btn btn-secondary btn-sm btn-sm-25 rounded-circle' onClick={onClose}>
                                <CloseIcon />
                            </button>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-0'>
                <div className="d-flex justify-content-center fs-16 mb-3">
                    {t('auth.contactYourManager')}
                </div>
                <div className='text-center'>
                    <img src='../img/login_forgot.png' style={{objectFit: 'none', position: 'relative', top: 20}}/>
                </div>
            </Modal.Body>
        </Modal >
    );
};

export default ForgotModal;
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

import {ROOT_URL} from 'utils/fetchRequest'
// import { TRANSLATIONS_MN } from "./translations/mn/translations";
// import { TRANSLATIONS_EN } from "./translations/en/translations";

const languages = ["mn", "en"];

let i18Root = ROOT_URL.includes('-test') ? "https://api-test" : "https://api"

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    whitelist: languages,
    fallbackLng: "mn",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: i18Root + ".eschool.mn/main/locale/eStudent/{{lng}}",
      crossDomain: true,
    }
  });

export default i18n;
